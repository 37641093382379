/***************************************************************
 * @NOTE: 医療機関詳細暫定移植で追加したファイルのため正規対応時に整理予定
 ***************************************************************/
/* eslint no-undef: 0 */
$(function () {
  // 「最近見た病院」に追加
  $.ajax({
    url: '/public/api/recent_hospitals',
    type: 'POST',
    cache: false,
    dataType: 'json',
    data: {
      institution_code: location.pathname.split('/')[2]
    }
  })
    .done(function () {
      // no-operation
    })
    .fail(function () {
      console.log('fail');
    });
});
